<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :profile="profile"/>


        <div class="container my-5">
            <div class="row" v-if="course.slug != ''">
                <div class="col-xs-12 col-md-7">
                    <h3>{{course.name}}</h3>
                    <video style="width: 100%; height: auto;" controls v-if="course.video">
                        <source :src="`/storage/courses/${course.video}`" type="video/mp4">
                    </video>
                    <h4>Dirigido a </h4>
                    <p>{{course.profile}}</p>
                    <h4 class="">Acerca de este curso</h4>
                    <p>{{course.about}}</p>
                    <h4>¿Qué aprenderás?</h4>
                    <p>{{course.learnthis}}</p>
                    <h4>Recomienda este curso</h4>
                    <p>¿Conoces alguien que le pudiera interesar este curso?</p>
                    <div>
                        <div class="input-group mb-3">
                            <input type="email" class="form-control">
                            <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="recommend">Recomendar</button>
                        </div>
                    </div>
                    <span v-if="actions.sent" class="alert alert-success">haz recomendado el curso</span>

                </div>
                <div class="col-xs-12 col-md-5 mb-5">
                    <h3 class="text-right">${{course.price | number_format}} MXN</h3>
                    <div v-if="showBuyForm">
                        <div v-if="step==1">

                            <button class="btn btn-golden p-3 w-100 btn-buynow">Datos personales</button>
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputEmail4">Email</label>
                                        <input type="email" class="form-control" id="inputEmail4">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="inputPassword4">Celular</label>
                                        <input type="password" class="form-control" id="inputPassword4">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputEmail4">Nombre</label>
                                        <input type="email" class="form-control" id="inputEmail4">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="inputPassword4">Apellidos</label>
                                        <input type="password" class="form-control" id="inputPassword4">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="gridCheck" @click="showTaxForm = !showTaxForm">
                                        <label class="form-check-label" for="gridCheck">
                                            Requiero factura
                                        </label>
                                    </div>
                                </div>
                            </form>
                            <div v-if="showTaxForm">
                                <b>Datos de facturación</b>
                                <div class="form-group">
                                    <label for="formGroupExampleInput">RFC</label>
                                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="formGroupExampleInput2">Razón Social</label>
                                    <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="formGroupExampleInput2">Uso del CFDI</label>
                                    <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="">
                                </div>
                                <div class="form-group">
                                    <label for="formGroupExampleInput2">Dirección Física</label>
                                    <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="">
                                </div>
                            </div>
                            <button class="bnt btn-primary" @click="step++">Siguiente</button>
                        </div>
                        <div v-if="step==2">
                            <ul class="list-group">
                                <li class="list-group-item"><input type="radio" name="paymentType"> Tarjeta de Crédito/débito</li>
                                <li class="list-group-item"><input type="radio" name="paymentType"> Transferencia</li>
                                <li class="list-group-item"><input type="radio" name="paymentType"> Pago en oxxo</li>
                            </ul>
                            <button class="bnt btn-primary" @click="step--">Anterior</button>
                            <button class="bnt btn-primary" @click="step++">Siguiente</button>
                        </div>
                        <div v-else-if="step==3">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label for="inputEmail4">Nombre en tarjeta</label>
                                        <input type="email" class="form-control" id="inputEmail4">
                                    </div>
                                </div>
                                 <div class="form-row">
                                    <div class="form-group col-md-8">
                                        <label for="inputEmail4">Número de tarjeta</label>
                                        <input type="email" class="form-control" id="inputEmail4">
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="inputEmail4">Exp.</label>
                                        <input type="email" class="form-control" id="inputEmail4">
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="inputEmail4">CVC</label>
                                        <input type="email" class="form-control" id="inputEmail4">
                                    </div>
                                </div>
                            </form>
                            <button class="bnt btn-primary" @click="step--">Anterior</button>
                            <button class="bnt btn-primary" @click="pay">Pagar</button>
                        </div>
                        <div v-else-if="step==4">
                            <h3>Felicidades</h3>
                            <p>En breve recibirás un correo con tu comprobante de pago.</p>
                            <a class="btn btn-primary" href="/profile">Ir al curso</a>

                        </div>
                    </div>

                    <div v-else>
                        <router-link v-if="profile.isLogged" class="btn btn-golden p-3 w-100 btn-buynow" :to="'/buy/' + course.slug">Comprar ahora</router-link>
                        <router-link to="/register" class="btn btn-golden p-3 w-100 btn-buynow" v-else >Registrate<br> para Comprar </router-link>
                        <p class="my-3"><b>Este curso incluye</b></p>
                        <template v-html=course.includes>
                        </template>
                        <div v-html="course.includes"></div>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
  components:{
      Header,
      Footer
  },
  name: 'Course',
  data() {
      return {
          user:this.$store.getters.getUser,
          showBuyForm: false,
          showTaxForm: false,
          step: 1,
          course: {},
          courses:[],
          profile: this.$store.getters.getUser,
          actions:{sending: false, sent:false }
      }
  },
  mounted() {
      //this.loadCourses().then(()=>{
      //  const course = this.courses.filter((course)=>{return course.url == this.$route.params.courseName})
      //  this.course = course[0]
      //})
      this.loadCourse(this.$route.params.courseName)
        .then((response)=>{
            this.course = response
      }).catch((error)=>{
          console.log(error);
      })
  },
  filters: {
    number_format: function (value)  {
        if (value == null) {
            return '-';
        }
        return parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  methods: {
      recommend() {
          this.actions.sending = true;
          this.actions.sent = true;
      },
      pay() {
          this.step = 4;
      },
      loadCourse(courseSlug) {
        return new Promise((resolve, reject)=> {
            axios.get(process.env.VUE_APP_APIURL + 'courses/' + courseSlug)
              .then((response)=>{
                resolve(response.data.data);
            }).catch((error)=>{
                reject(error);
            })
        })
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}
.btn-buynow{font-size:30px}
</style>
